let isDown = false;
let startX;
let scrollLeft;
const slider = document.querySelector('.slider');

const end = () => {
    isDown = false;
	slider?.classList.remove('active');
}

const start = (e) => {
	isDown = true;
	slider?.classList.add('active');

	if(slider instanceof HTMLElement) {
		startX = e.pageX || (e.touches && e.touches[0] ? e.touches[0].pageX - slider.offsetLeft : 0);
	}
	scrollLeft = slider?.scrollLeft;	
}

const move = (e) => {
	if(!isDown) return;
	
	if(slider instanceof HTMLElement) {
		const x = e.pageX || (e.touches && e.touches[0] ? e.touches[0].pageX - slider.offsetLeft : 0);
		
		const dist = (x - startX);
		slider.scrollLeft = scrollLeft - dist;
	}
}

(() => {
	slider?.addEventListener('mousedown', start);
	slider?.addEventListener('touchstart', start, {
		passive: true
	});

	slider?.addEventListener('mousemove', move);
	slider?.addEventListener('touchmove', move, {
		passive: true
	});

	slider?.addEventListener('mouseleave', end);
	slider?.addEventListener('mouseup', end);
	slider?.addEventListener('touchend', end, {
		passive: true
	});
})();
