import 'whatwg-fetch'
import './simpleDragSlider';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from "@sentry/tracing";
import config from '../../config/config.json';

if (config.environment == 'prod' || config.environment == 'staging') {
    Sentry.init({
      dsn: config.sentry.dsn,
      //release: config.sentry.release,
      environment: config.environment,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
}

const onDomReady = (callBack: () => void) => {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', callBack);
    }
    else {
      callBack();
    }
}

onDomReady(() => {
    window.addEventListener('scroll', () => {
        if(window.scrollY >= window.innerHeight) {
            document.getElementById('toggle')?.classList.add('sticked');
        } else {
            document.getElementById('toggle')?.classList.remove('sticked');
        }
    }, {
        passive: true
    });

    document.getElementById('toggle')?.addEventListener('click', () => {
        document.getElementById('body')?.classList.toggle("overflowhidden");
        document.getElementById('toggle')?.classList.toggle('active');
        document.getElementById('nav')?.classList.toggle('active');
        document.querySelectorAll('.tracker-wrapper').forEach(element => {
            element.classList.toggle('active');
        });
    });
    
    document.getElementById('navHeader')?.querySelectorAll('a').forEach(element => {
        //Skip if it's not an anchor
        if(element.href.indexOf('#') === -1) {
            return;
        }

        element.addEventListener('click', (e) => {
            e.preventDefault();
            
            if(document.getElementById('nav')?.classList.contains('active')) {
                document.getElementById('body')?.classList.toggle("overflowhidden");
                document.getElementById('toggle')?.classList.toggle('active');
                document.getElementById('nav')?.classList.toggle('active');
                document.querySelectorAll('.tracker-wrapper').forEach(element => {
                    element.classList.toggle('active');
                });
            }
    
            const target = document.getElementById(element.getAttribute('href')?.substring(1) || '');
            
            if(target) {
                window.scrollTo({
                    top: target.offsetTop,
                    behavior: 'smooth',
                });
            }
        });
    });

    document.getElementById('contact-form')?.addEventListener('submit', (e) => {
        e.preventDefault();
        if(!(e.target instanceof HTMLFormElement)) {
            return false;
        }

        const btn = document.getElementById('contact-btn');
        btn?.setAttribute('disabled', '');
        
        const error = document.getElementById('contact-error');
        if(error) {
            error.classList.add('hide');
            error.textContent = '';
        }

        let data = new FormData(e.target);

        const triggerError = () => {
            btn?.removeAttribute('disabled');

            if(error) {
                error.classList.remove('hide');
                error.textContent = error.getAttribute('data-error-txt');
            }
        };

        fetch('/ajax/send-mail', {
            method: 'POST',
            body: data,
        }).then(response => {
            if(response.status !== 200) {
                triggerError();
                return;
            }

            if(window.fbq) {
                window.fbq('track', 'Contact');
            }
            
            if(btn) {
                btn.classList.add('success');
                btn.textContent = btn.getAttribute('data-sent-txt');
            }
        }).catch(reason => {
            triggerError();
        });
        return false;
    })

    //Pixel events
    document.querySelectorAll('.track-btn').forEach((elem) => {
        elem.addEventListener('click', (e) => {
            if(window.fbq) {
                window.fbq('track', 'Lead', {
                    lead_type: elem.getAttribute('data-type'),
                    lead_destination: elem.getAttribute('data-destination'),
                });
            }
        });
    });
});